// src/contexts/LanguageContext.js
import React, { createContext, useState } from 'react';

// Criação do contexto
export const LanguageContext = createContext();

// Provedor do contexto
export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('pt'); // Idioma padrão

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    // Aqui você pode adicionar lógica para carregar textos no novo idioma
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};