import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/loading";

const splashScreen = {
  enabled: true,
  animation: splashAnimation,
  duration: 3000
};

const illustration = {
  animated: true
};

const greeting = {
  username: "Henrique Lauxen",
  title: "Olá, me chamo Henrique!",
  subTitle: emoji(
    "Um desenvolvedor de software 🚀 obtendo experiência desenvolvendo sites e aplicações com React, C# e SQL."
  ),
  displayGreeting: true
};

// Social Media Links

const socialMediaLinks = {
  instagram: "https://www.instagram.com/henrique_lauxen/",
  github: "https://github.com/HenriqueLauxen",
  linkedin: "https://www.linkedin.com/in/henriquelauxen/",
  facebook: "https://www.facebook.com/lauxenhenrique/",
  display: true
};

// Skills Section

const skillsSection = {
  title: "Habilidades",
  subTitle: "Atualmente trabalho com as stacks FrontEnd e BackEnd, sendo as tecnologias:",
  skills: [
    emoji(
      "⚡ Desenvolvo interfaces interativas e responsivas com React."
    ),
    emoji("⚡ Desenvolvimento backend eficiente utilizando C#"),
    emoji(
      "⚡ Busco aprimorar meus conhecimentos em serviços de nuvem da Microsoft Azure"
    )
  ],
  softwareSkills: [
    {
      skillName: "HTML5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "CSS3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "Sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "C#",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "ReactJS",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "SQL",
      fontAwesomeClassname: "fas fa-database"
    },
  ],
  display: true
};

// Education Section

const educationInfo = {
  display: true,
  schools: [
    {
      schoolName: "BLAU NUNES",
      logo: require("./assets/images/blaununeslogo.jpg"),
      subHeader: "Ensino Médio",
      duration: "Fev 2021 - Dezembro 2023",
      desc: "Formação no Ensino Médio",
    },
    {
      schoolName: "Udemy",
      logo: require("./assets/images/udemylogo.png"),
      subHeader: "C#: Primeiros Passos | Nélio Alves",
      duration: "2023 | Duração: 8 Horas",
      desc: "Conhecimentos básicos em C#",
    },
    {
      schoolName: "Curso em Video",
      logo: require("./assets/images/cursoemvideo.jpg"),
      subHeader: "HTML 5 & CSS 3 [Todos Módulos]",
      duration: "2023 | Duração: 200 Horas",
      desc: "Aprofundei meus nas tecnologias HTML5 & CSS3",
    },
    {
      schoolName: "Welisson Arley",
      logo: require("./assets/images/csharp.png"),
      subHeader: ".Net Core: Orientado ao Mercado de Trabalho",
      duration: "2024 | Duração: 42 Horas",
      desc: "Aprofundei meus conhecimentos com .Net, orientando meus conhecimentos ao mercado de trabalho",
    },
  ]
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Jovem Aprendiz",
      company: "Três Tentos AgroIndustrial S/A",
      companylogo: require("./assets/images/logoTT.png"),
      date: "Maio 2023 – Atualmente",
      desc: "Obtive vivência no desenvolvimento trabalhando no setor de Agricultura Digital",
      descBullets: [
        "Conhecimentos em Azure;",
        "Vivências com demasiadas tecnologias, como: C#, React e SQL;",
        "Domínio de Ferramentas voltadas ao desenvolvimento"
      ]
    },
  ]
};

// Some big projects you have worked on

const bigProjects = {
  title: "Meus Projetos",
  subtitle: "MEU PORTFÓLIO DE PROJETOS",
  projects: [
    {
      image: require("./assets/images/logoTT.png"),
      projectName: "Henrique Lauxen",
      projectDesc: "Desenvolvimento do meu próprio Site",
      footerLink: [
        {
          name: "Acesse aqui",
          url: "https://henriquelauxen.com/"
        }
        //  you can add extra buttons here.
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("CONTATO"),
  subtitle:
    "Estou disponível nos contatos abaixo:",
  number: "+55 54 99201-3789",
  email_address: "contato@henriquelauxen.com"
};

const commentsCard = {
  title: "Comentários",
  subtitle:
  emoji("Caso queira deixar um comentário em meu site, clique no botão de adicionar"),
}

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  workExperiences,
  bigProjects,
  commentsCard,
  contactInfo
};
